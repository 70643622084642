(function ($) {
  $.fn.setToolTip1 = function () {
    console.log("test for function calling");
  };

  $.fn.customFuzzyComplete = function (places, options) {
    // console.log("test fuzzyComplete");

    $("#locationAddress").fuzzyComplete(places, options);
  };
})(jQuery);
